import { BrowserRouter, Route, Routes } from "react-router-dom";
import ConnectWallet from "./Pages/ConnectWallet";
import Home from "./Pages/Home";
import { Navbar } from "./Components";
import { useEffect, useState } from "react";

function App() {
  const [modal, setModal] = useState(false);

  // Step 2: Use useEffect to update state after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      // Step 3: Update state after 3 seconds
      setModal(true);
    }, 3000);

    // Step 4: Cleanup the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures that the effect runs only once

  return (
    <BrowserRouter>
      <div className={modal ? "blur-sm" : 'blur-none'}>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home setModal={setModal}/>} />
        </Routes>
      </div>
      {modal && <ConnectWallet setModal={setModal}/>}
    </BrowserRouter>
  );
}

export default App;
