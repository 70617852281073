const Overview = ({ text, img, position, wrapper, typo }) => {
  return (
    <div className={wrapper}>
      <p className={typo}>{text}</p>
      <img className={position} src={img} alt="imagem" />
    </div>
  );
};

export default Overview;
